import React from "react"
import Content from "./content"
import { graphql, useStaticQuery } from "gatsby"

const SponsorWrapper = props => (
  <a
    href={props.link}
    target="_blank"
    className="w-[300px] h-[140px] flex items-center mr-24 mt-4"
  >
    {props.children}
  </a>
)

const Sponsors = props => {
  const { sponsors, ambassadors } = useStaticQuery(graphql`
    {
      sponsors: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(sponsors)/.*.md$/" } }
      ) {
        edges {
          node {
            frontmatter {
              image
              order
              link
              maxWidth
            }
          }
        }
      }
      ambassadors: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(ambassadors)/.*.md$/" } }
      ) {
        edges {
          node {
            frontmatter {
              image
              order
              link
              maxWidth
            }
          }
        }
      }
    }
  `)

  const sponsorsArray = sponsors.edges
  sponsorsArray.sort((a, b) =>
    a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1
  )

  const ambassadorsArray = ambassadors.edges
  ambassadorsArray.sort((a, b) =>
    a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1
  )

  return (
    <Content>
      <p className="text-4xl text-orange font-fontspring-bold">
        Thank you to my partners
      </p>
      <div className="flex flex-col ml-16 mt-14">
        <p className="text-xl text-white">Sponsors</p>
        <div className="flex flex-wrap">
          {sponsorsArray.map(sponsor => (
            <SponsorWrapper link={sponsor.node.frontmatter.link}>
              <img
                src={sponsor.node.frontmatter.image}
                style={{ maxWidth: sponsor.node.frontmatter.maxWidth }}
              />
            </SponsorWrapper>
          ))}
        </div>
      </div>
      {/* <div className="flex flex-col ml-16 mt-14">
        <p className="text-xl text-white">Ambassador</p>
        <div className="flex flex-wrap">
          {ambassadorsArray.map(ambassador => (
            <SponsorWrapper link={ambassador.node.frontmatter.link}>
              <img
                src={ambassador.node.frontmatter.image}
                style={{ maxWidth: ambassador.node.frontmatter.maxWidth }}
              />
            </SponsorWrapper>
          ))}
        </div>
      </div> */}
    </Content>
  )
}

export default Sponsors
